<template>
  <v-card class="ma-5" outlined>
    <div class="home-container">
      <section class="my-10">
        <h3>Sehr geehrte Damen und Herren</h3>
        <p>
          Ihrer Nutzerkennung sind verschiedene Daten zur
          <b>Geodatenbereitstellung</b> zugewiesen.
        </p>
        <p>Bitte treffen Sie eine Auswahl.</p>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(router, i) in routers"
              :key="i"
              :to="router.route"
            >
              <v-list-item-content>
                <a>
                  <v-list-item-title v-text="router.title"></v-list-item-title>
                </a>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <TheSupportInfo />
      </section>
    </div>
    <v-footer>
      <v-spacer></v-spacer>
      <div class="geoportal--text">
        &copy; IT.NRW
        {{ new Date().getFullYear() }}
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import { app_routers as appRouters } from '@/config';
import TheSupportInfo from '@/components/TheSupportInfo.vue';
export default {
  components: {
    TheSupportInfo
  },
  computed: {
    routers() {
      return appRouters
        .filter(route => this.$store.state.auth.user.roles.includes(route.role))
        .filter(
          route => route.title !== 'Home' && route.title !== 'Download-Center'
        );
    }
  }
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: center;
}
</style>
